import { ILocation } from "@/models/Location";

class AddressUtils {
  floorDoor(floor: string | undefined, door: string | undefined): string {
    if (floor && door) return floor + "-" + door;
    return "";
  }

  locationName(location: ILocation | undefined): string | null {
    const district = location?.district?.name;
    const neighborhood = location?.neighborhood?.name;
    const city = location?.city?.name;
    const state = location?.state?.name;
    if (district && city) return district + ", " + city;
    else if (neighborhood && city) return neighborhood + ", " + city;
    else if (city && state) return city + ", " + state;
    return null;
  }
}
export default new AddressUtils();
