import {
  OwnerPropertyCardType,
  PropertyActiveProcessCardType,
} from "@/enums/PropertyCardType";
import { PropertyStatus } from "@/enums/PropertyStatus";
import { RentalLeasingStatus } from "@/enums/RentalLeasing";
import { IRentalLeasing } from "@/models/RentalLeasing";

export default class OwnerCardsMappingService {
  static getCardType(
    propertyStatus: PropertyStatus,
    leasings: IRentalLeasing[],
    propertyIsVisible = false
  ): OwnerPropertyCardType | null {
    const hasSomeLeasingType = this.hasSomeLeasingType(leasings);

    if (
      propertyStatus === PropertyStatus.CAPTURED &&
      hasSomeLeasingType[RentalLeasingStatus.PENDING_EXIT]
    )
      return OwnerPropertyCardType.CAPTURED_WITH_ACTIVE_LEASING;
    if (propertyStatus === PropertyStatus.CAPTURED) {
      return OwnerPropertyCardType.ONBOARDING;
    }
    if (
      propertyStatus === PropertyStatus.PUBLISHED &&
      hasSomeLeasingType[RentalLeasingStatus.PENDING_EXIT]
    )
      return OwnerPropertyCardType.PUBLISHED_WITH_ACTIVE_LEASING;
    if (propertyStatus === PropertyStatus.PUBLISHED) {
      if (propertyIsVisible) return OwnerPropertyCardType.PUBLISHED;
      return OwnerPropertyCardType.ONBOARDING;
    }
    if (
      propertyStatus === PropertyStatus.RENTAL_MANAGEMENT &&
      hasSomeLeasingType[RentalLeasingStatus.PENDING_EXIT] &&
      (hasSomeLeasingType[RentalLeasingStatus.PENDING_CONFIRMATION] ||
        hasSomeLeasingType[RentalLeasingStatus.PENDING_ENTRY])
    )
      return OwnerPropertyCardType.PENDING_SIGNATURE;
    if (
      propertyStatus === PropertyStatus.RENTAL_MANAGEMENT &&
      hasSomeLeasingType[RentalLeasingStatus.PENDING_EXIT]
    )
      return OwnerPropertyCardType.RESIGNATION;
    if (
      propertyStatus === PropertyStatus.RENTAL_MANAGEMENT &&
      (hasSomeLeasingType[RentalLeasingStatus.PENDING_CONFIRMATION] ||
        hasSomeLeasingType[RentalLeasingStatus.PENDING_ENTRY])
    )
      return OwnerPropertyCardType.RESERVATION;
    if (
      propertyStatus === PropertyStatus.RENTAL_MANAGEMENT &&
      (hasSomeLeasingType[RentalLeasingStatus.ONGOING] ||
        hasSomeLeasingType[RentalLeasingStatus.WON])
    )
      return OwnerPropertyCardType.IN_MANAGEMENT;
    if (
      propertyStatus === PropertyStatus.RENTAL_MANAGEMENT &&
      (hasSomeLeasingType[RentalLeasingStatus.LOST] || !leasings.length)
    )
      return OwnerPropertyCardType.NOT_RENTED;
    if (
      propertyStatus === PropertyStatus.LOST ||
      propertyStatus === PropertyStatus.LOST_NOT_PUBLISHED
    )
      return OwnerPropertyCardType.CANCELLED;

    return null;
  }

  static getActiveProcessCardTypes(
    propertyStatus: PropertyStatus,
    leasings: IRentalLeasing[],
    propertyIsVisible = false
  ): PropertyActiveProcessCardType[] {
    const hasSomeLeasingType = this.hasSomeLeasingType(leasings);
    const hasAllLeasingsLost = leasings.every(
      (leasing) => leasing.status === RentalLeasingStatus.LOST
    );

    const cards: PropertyActiveProcessCardType[] = [];

    if (hasSomeLeasingType[RentalLeasingStatus.PENDING_EXIT]) {
      cards.push(PropertyActiveProcessCardType.RESIGNATION);
      if (propertyStatus === PropertyStatus.CAPTURED)
        cards.push(PropertyActiveProcessCardType.CAPTURED);
      else if (propertyStatus === PropertyStatus.PUBLISHED)
        cards.push(PropertyActiveProcessCardType.PUBLISHED);
      else if (
        hasSomeLeasingType[RentalLeasingStatus.PENDING_CONFIRMATION] ||
        hasSomeLeasingType[RentalLeasingStatus.PENDING_ENTRY]
      )
        cards.push(PropertyActiveProcessCardType.PENDING_SIGNATURE);
      return cards;
    }

    if (propertyStatus === PropertyStatus.CAPTURED)
      return [PropertyActiveProcessCardType.CAPTURED];
    if (propertyStatus === PropertyStatus.PUBLISHED) {
      if (propertyIsVisible) return [PropertyActiveProcessCardType.PUBLISHED];
      else return [PropertyActiveProcessCardType.CAPTURED_NOT_CLICKABLE];
    }
    if (
      propertyStatus === PropertyStatus.RENTAL_MANAGEMENT &&
      (hasSomeLeasingType[RentalLeasingStatus.PENDING_CONFIRMATION] ||
        hasSomeLeasingType[RentalLeasingStatus.PENDING_ENTRY])
    ) {
      return [PropertyActiveProcessCardType.PENDING_SIGNATURE];
    }
    if (
      propertyStatus === PropertyStatus.RENTAL_MANAGEMENT &&
      (hasSomeLeasingType[RentalLeasingStatus.ONGOING] ||
        hasSomeLeasingType[RentalLeasingStatus.WON])
    )
      return [PropertyActiveProcessCardType.IN_MANAGEMENT];
    if (
      propertyStatus === PropertyStatus.RENTAL_MANAGEMENT &&
      hasSomeLeasingType[RentalLeasingStatus.PENDING_EXIT] &&
      (hasSomeLeasingType[RentalLeasingStatus.PENDING_CONFIRMATION] ||
        hasSomeLeasingType[RentalLeasingStatus.PENDING_ENTRY])
    )
      return [PropertyActiveProcessCardType.PENDING_SIGNATURE];
    if (
      propertyStatus === PropertyStatus.RENTAL_MANAGEMENT &&
      (!leasings.length || hasAllLeasingsLost)
    )
      return [PropertyActiveProcessCardType.NOT_RENTED];

    if (
      propertyStatus === PropertyStatus.LOST ||
      propertyStatus === PropertyStatus.LOST_NOT_PUBLISHED
    )
      return [PropertyActiveProcessCardType.CANCELLED];

    return [];
  }

  /* eslint-disable no-unused-vars */
  static hasSomeLeasingType(leasings: IRentalLeasing[]): {
    [key in RentalLeasingStatus]: boolean;
  } {
    const hasSomeLeasingPendingConfirmation = leasings.some(
      (leasing) => leasing.status === RentalLeasingStatus.PENDING_CONFIRMATION
    );
    const hasSomeLeasingPendingEntry = leasings.some(
      (leasing) => leasing.status === RentalLeasingStatus.PENDING_ENTRY
    );
    const hasSomeLeasingPendingExit = leasings.some(
      (leasing) => leasing.status === RentalLeasingStatus.PENDING_EXIT
    );
    const hasSomeLeasingOngoing = leasings.some(
      (leasing) => leasing.status === RentalLeasingStatus.ONGOING
    );
    const hasSomeLeasingWon = leasings.some(
      (leasing) => leasing.status === RentalLeasingStatus.WON
    );
    const hasSomeLeasingLost = leasings.some(
      (leasing) => leasing.status === RentalLeasingStatus.LOST
    );

    return {
      [RentalLeasingStatus.PENDING_CONFIRMATION]:
        hasSomeLeasingPendingConfirmation,
      [RentalLeasingStatus.PENDING_ENTRY]: hasSomeLeasingPendingEntry,
      [RentalLeasingStatus.PENDING_EXIT]: hasSomeLeasingPendingExit,
      [RentalLeasingStatus.ONGOING]: hasSomeLeasingOngoing,
      [RentalLeasingStatus.WON]: hasSomeLeasingWon,
      [RentalLeasingStatus.LOST]: hasSomeLeasingLost,
    };
  }
}
