
import { Component, Prop, Vue } from "vue-property-decorator";
import { HousfyPropertyCard } from "housfy-ui-lib";
import { IOwnerProperty } from "@/models/OwnerProperties";
import HousfySvg from "@/components/ui/HousfySvg.vue";
import {
  OwnerPropertyCardType,
  PropertyCardSubtitleType,
  PropertyCardBadgeType,
} from "@/enums/PropertyCardType";
import {
  IPropertyStatus,
  IPropertyStatusSubtitle,
  IPropertyStatusBadge,
} from "@/models/Status";
import { TranslateResult } from "vue-i18n";
import DateUtils from "@/services/utils/DateUtils";
import AddressUtils from "@/services/utils/AddressUtils";
import {
  OWNER_PROPERTY_CARD_TYPE,
  PROPERTY_CARD_SUBTITLE_TYPE,
  PROPERTY_CARD_BADGE_TYPE,
} from "@/constants/PropertyCardType";
import OwnerCardsMappingService from "@/services/OwnerCardsMappingService";

@Component({
  name: "OwnerPropertyCardContent",
  components: {
    HousfyPropertyCard,
    HousfySvg,
  },
})
export default class OwnerPropertyCardContent extends Vue {
  @Prop({ type: Boolean, default: true })
  isClickable!: boolean;
  @Prop({ type: Object, default: () => ({} as IOwnerProperty) })
  property!: IOwnerProperty;
  @Prop({ type: String, required: true })
  dataQa!: string;

  get cardType(): OwnerPropertyCardType | null {
    const propertyStatus = this.property.propertyStatus;
    const leasings = this.property.activeRentalLeasings;
    const propertyIsVisible = this.property.isVisible;

    return OwnerCardsMappingService.getCardType(
      propertyStatus,
      leasings,
      propertyIsVisible
    );
  }

  get statusData(): IPropertyStatus | null {
    if (!this.cardType) return null;

    return OWNER_PROPERTY_CARD_TYPE[
      this.cardType as keyof typeof OWNER_PROPERTY_CARD_TYPE
    ];
  }

  get badgeType() {
    return PropertyCardBadgeType;
  }

  get floorDoor(): string {
    return AddressUtils.floorDoor(this.property.floor, this.property.door);
  }

  get locationName(): string | null {
    return AddressUtils.locationName(this.property.location);
  }

  get mainPhoto(): string {
    return this.property?.mainPhoto || "";
  }

  get statusSubtitle(): TranslateResult | null {
    if (!this.statusData) return null;

    if (this.statusData.needsDateInfo) {
      let subtitleDate = this.property.validityDate;
      if (!subtitleDate) {
        subtitleDate =
          this.property.validityDate ||
          this.property.lastPropertyStatusChangedAt;
        if (this.cardType === OwnerPropertyCardType.IN_MANAGEMENT)
          subtitleDate = this.property.activeRentalLeasings[0].validityDate;
      }

      if (subtitleDate) {
        const { day, month, year } = DateUtils.formatDate(subtitleDate);
        const formattedDate =
          day + " " + this.$t("common." + month + "Short") + " " + year;

        return this.statusData.subtitle
          ? this.$t(this.statusData.subtitle, {
              date: formattedDate,
            })
          : formattedDate;
      }
    }

    return this.statusData.subtitle ? this.$t(this.statusData.subtitle) : null;
  }
  get subtitleStyle(): IPropertyStatusSubtitle {
    if (!this.statusData) return {};
    const subtitleType = this.statusData.subtitleType;
    return subtitleType
      ? PROPERTY_CARD_SUBTITLE_TYPE[subtitleType]
      : PROPERTY_CARD_SUBTITLE_TYPE[PropertyCardSubtitleType.DEFAULT];
  }
  get badgeStyle(): IPropertyStatusBadge {
    if (!this.statusData) return {};
    const badgeType = this.statusData.badgeType;
    return badgeType
      ? PROPERTY_CARD_BADGE_TYPE[badgeType]
      : PROPERTY_CARD_BADGE_TYPE[PropertyCardBadgeType.DEFAULT];
  }
}
