import {
  testMyRentalsShallowMount,
  myRentalsShallowMount,
} from "@/tests/unit/setup";
import OwnerPropertyCardContent from "@/components/OwnerPropertyCardContent.vue";
import { PropertyStatus } from "@/enums/PropertyStatus";
import { RentalLeasingStatus } from "@/enums/RentalLeasing";
import { IOwnerProperty } from "@/models/OwnerProperties";
import {
  OwnerPropertyCardType,
  PropertyCardBadgeType,
  PropertyCardSubtitleType,
} from "@/enums/PropertyCardType";
import {
  OWNER_PROPERTY_CARD_TYPE,
  PROPERTY_CARD_SUBTITLE_TYPE,
  PROPERTY_CARD_BADGE_TYPE,
} from "@/constants/PropertyCardType";
import DateUtils from "@/services/utils/DateUtils";

jest.mock("@/services/MyRentalsConfig");

const property: IOwnerProperty = {
  propertyId: 1,
  propertyUuid: "e8b7941a-5907-43fc-8b41-fd7761d833aew",
  location: {
    id: 1801,
    name: "Hostafrancs",
    state: { id: 1650, name: "Barcelona" },
    city: { id: 1737, name: "Barcelona" },
    district: { id: 1799, name: "Sants-Montjuïc" },
    neighborhood: { id: 1801, name: "Hostafrancs" },
  },
  fullAddress: "Carrer Del Consell De Cent 1,  Barcelona",
  floor: "2",
  door: "3",
  mainPhoto: "link",
  isVisible: false,
  propertyStatus: PropertyStatus.CAPTURED,
  lastPropertyStatusChangedAt: "2022-06-15T16:02:00.000000Z",
  activeRentalLeasings: [],
};

const cardTypeLead: IOwnerProperty = {
  propertyId: 1,
  propertyUuid: "e8b7941a-5907-43fc-8b41-fd7761d833aew",
  location: {
    id: 1801,
    name: "Hostafrancs",
    state: { id: 1650, name: "Barcelona" },
    city: { id: 1737, name: "Barcelona" },
    district: { id: 1799, name: "Sants-Montjuïc" },
    neighborhood: { id: 1801, name: "Hostafrancs" },
  },
  fullAddress: "Carrer Del Consell De Cent 1,  Barcelona",
  floor: "2",
  door: "3",
  mainPhoto: "link",
  isVisible: false,
  propertyStatus: PropertyStatus.CAPTURED,
  lastPropertyStatusChangedAt: "2022-06-15T16:02:00.000000Z",
  activeRentalLeasings: [],
};

const noStatusDataLead: IOwnerProperty = {
  propertyId: 1,
  propertyUuid: "e8b7941a-5907-43fc-8b41-fd7761d833aew",
  location: {
    id: 1801,
    name: "Hostafrancs",
    state: { id: 1650, name: "Barcelona" },
    city: { id: 1737, name: "Barcelona" },
    district: { id: 1799, name: "Sants-Montjuïc" },
    neighborhood: { id: 1801, name: "Hostafrancs" },
  },
  fullAddress: "Carrer Del Consell De Cent 1,  Barcelona",
  floor: "2",
  door: "3",
  mainPhoto: "link",
  isVisible: false,
  propertyStatus: PropertyStatus.RENTAL_MANAGEMENT,
  lastPropertyStatusChangedAt: "2022-06-15T16:02:00.000000Z",
  activeRentalLeasings: [],
};
const defaultProps = {
  property,
};

const component = (data = {}, props = defaultProps) => {
  return myRentalsShallowMount(OwnerPropertyCardContent, {
    data,
    props,
  });
};

beforeEach(() => {
  jest.restoreAllMocks();
});

describe("OwnerPropertyCardContent", () => {
  testMyRentalsShallowMount(OwnerPropertyCardContent, { props: defaultProps });

  describe("Computed", () => {
    describe("cardType", () => {
      it("Should return card type CAPTURED_WITH_ACTIVE_LEASING", () => {
        // given
        cardTypeLead.propertyStatus = PropertyStatus.CAPTURED;
        cardTypeLead.activeRentalLeasings = [
          {
            leasingId: 1,
            status: RentalLeasingStatus.PENDING_EXIT,
            lastStatusChangedAt: "",
          },
        ];
        const card = component({}, { property: cardTypeLead });
        // when
        // then
        expect(card.vm.cardType).toEqual(
          OwnerPropertyCardType.CAPTURED_WITH_ACTIVE_LEASING
        );
      });
      it("Should return card type ONBOARDING", () => {
        // given
        cardTypeLead.propertyStatus = PropertyStatus.CAPTURED;
        cardTypeLead.activeRentalLeasings = [];
        const card = component({}, { property: cardTypeLead });
        // when
        // then
        expect(card.vm.cardType).toEqual(OwnerPropertyCardType.ONBOARDING);
      });
      it("Should return card type PUBLISHED_WITH_ACTIVE_LEASING", () => {
        // given
        cardTypeLead.propertyStatus = PropertyStatus.PUBLISHED;
        cardTypeLead.activeRentalLeasings = [
          {
            leasingId: 1,
            status: RentalLeasingStatus.PENDING_EXIT,
            lastStatusChangedAt: "",
          },
        ];
        const card = component({}, { property: cardTypeLead });
        // when
        // then
        expect(card.vm.cardType).toEqual(
          OwnerPropertyCardType.PUBLISHED_WITH_ACTIVE_LEASING
        );
      });
      it("Should return card type PUBLISHED", () => {
        // given
        cardTypeLead.propertyStatus = PropertyStatus.PUBLISHED;
        cardTypeLead.isVisible = true;
        cardTypeLead.activeRentalLeasings = [];
        const card = component({}, { property: cardTypeLead });
        // when
        // then
        expect(card.vm.cardType).toEqual(OwnerPropertyCardType.PUBLISHED);
      });
      it("Should return card type ONBOARDING", () => {
        // given
        cardTypeLead.propertyStatus = PropertyStatus.PUBLISHED;
        cardTypeLead.isVisible = false;
        cardTypeLead.activeRentalLeasings = [];
        const card = component({}, { property: cardTypeLead });
        // when
        // then
        expect(card.vm.cardType).toEqual(OwnerPropertyCardType.ONBOARDING);
      });
      it("Should return card type PENDING_SIGNATURE", () => {
        // given
        cardTypeLead.propertyStatus = PropertyStatus.RENTAL_MANAGEMENT;
        cardTypeLead.activeRentalLeasings = [
          {
            leasingId: 1,
            status: RentalLeasingStatus.PENDING_EXIT,
            lastStatusChangedAt: "",
          },
          {
            leasingId: 1,
            status: RentalLeasingStatus.PENDING_ENTRY,
            lastStatusChangedAt: "",
          },
        ];
        const card = component({}, { property: cardTypeLead });
        // when
        // then
        expect(card.vm.cardType).toEqual(
          OwnerPropertyCardType.PENDING_SIGNATURE
        );
      });
      it("Should return card type PENDING_SIGNATURE", () => {
        // given
        cardTypeLead.propertyStatus = PropertyStatus.RENTAL_MANAGEMENT;
        cardTypeLead.activeRentalLeasings = [
          {
            leasingId: 1,
            status: RentalLeasingStatus.PENDING_EXIT,
            lastStatusChangedAt: "",
          },
          {
            leasingId: 1,
            status: RentalLeasingStatus.PENDING_CONFIRMATION,
            lastStatusChangedAt: "",
          },
        ];
        const card = component({}, { property: cardTypeLead });
        // when
        // then
        expect(card.vm.cardType).toEqual(
          OwnerPropertyCardType.PENDING_SIGNATURE
        );
      });
      it("Should return card type RESIGNATION", () => {
        // given
        cardTypeLead.propertyStatus = PropertyStatus.RENTAL_MANAGEMENT;
        cardTypeLead.activeRentalLeasings = [
          {
            leasingId: 1,
            status: RentalLeasingStatus.PENDING_EXIT,
            lastStatusChangedAt: "",
          },
        ];
        const card = component({}, { property: cardTypeLead });
        // when
        // then
        expect(card.vm.cardType).toEqual(OwnerPropertyCardType.RESIGNATION);
      });
      it("Should return card type RESERVATION", () => {
        // given
        cardTypeLead.propertyStatus = PropertyStatus.RENTAL_MANAGEMENT;
        cardTypeLead.activeRentalLeasings = [
          {
            leasingId: 1,
            status: RentalLeasingStatus.PENDING_CONFIRMATION,
            lastStatusChangedAt: "",
          },
        ];
        const card = component({}, { property: cardTypeLead });
        // when
        // then
        expect(card.vm.cardType).toEqual(OwnerPropertyCardType.RESERVATION);
      });
      it("Should return card type RESERVATION", () => {
        // given
        cardTypeLead.propertyStatus = PropertyStatus.RENTAL_MANAGEMENT;
        cardTypeLead.activeRentalLeasings = [
          {
            leasingId: 1,
            status: RentalLeasingStatus.PENDING_ENTRY,
            lastStatusChangedAt: "",
          },
        ];
        const card = component({}, { property: cardTypeLead });
        // when
        // then
        expect(card.vm.cardType).toEqual(OwnerPropertyCardType.RESERVATION);
      });
      it("Should return card type IN_MANAGEMENT", () => {
        // given
        cardTypeLead.propertyStatus = PropertyStatus.RENTAL_MANAGEMENT;
        cardTypeLead.activeRentalLeasings = [
          {
            leasingId: 1,
            status: RentalLeasingStatus.WON,
            lastStatusChangedAt: "",
          },
        ];
        const card = component({}, { property: cardTypeLead });
        // when
        // then
        expect(card.vm.cardType).toEqual(OwnerPropertyCardType.IN_MANAGEMENT);
      });
      it("Should return card type IN_MANAGEMENT", () => {
        // given
        cardTypeLead.propertyStatus = PropertyStatus.RENTAL_MANAGEMENT;
        cardTypeLead.activeRentalLeasings = [
          {
            leasingId: 1,
            status: RentalLeasingStatus.ONGOING,
            lastStatusChangedAt: "",
          },
        ];
        const card = component({}, { property: cardTypeLead });
        // when
        // then
        expect(card.vm.cardType).toEqual(OwnerPropertyCardType.IN_MANAGEMENT);
      });
      it("Should return card type NOT_RENTED", () => {
        // given
        cardTypeLead.propertyStatus = PropertyStatus.RENTAL_MANAGEMENT;
        cardTypeLead.activeRentalLeasings = [
          {
            leasingId: 1,
            status: RentalLeasingStatus.LOST,
            lastStatusChangedAt: "",
          },
        ];
        const card = component({}, { property: cardTypeLead });
        // when
        // then
        expect(card.vm.cardType).toEqual(OwnerPropertyCardType.NOT_RENTED);
      });
      it("Should return card type CANCELLED", () => {
        // given
        cardTypeLead.propertyStatus = PropertyStatus.LOST;
        cardTypeLead.activeRentalLeasings = [];
        const card = component({}, { property: cardTypeLead });
        // when
        // then
        expect(card.vm.cardType).toEqual(OwnerPropertyCardType.CANCELLED);
      });
      it("Should return card type CANCELLED", () => {
        // given
        cardTypeLead.propertyStatus = PropertyStatus.LOST_NOT_PUBLISHED;
        cardTypeLead.activeRentalLeasings = [];
        const card = component({}, { property: cardTypeLead });
        // when
        // then
        expect(card.vm.cardType).toEqual(OwnerPropertyCardType.CANCELLED);
      });
    });

    describe("statusData", () => {
      it("Should return null if there isn't statusData", () => {
        // given
        const card = component({}, { property: noStatusDataLead });
        // when
        // then
        expect(card.vm.statusData).toBe(
          OWNER_PROPERTY_CARD_TYPE[OwnerPropertyCardType.NOT_RENTED]
        );
      });

      it("Should return correct status data", () => {
        // given
        const card = component();
        // when
        // then
        expect(card.vm.statusData).toEqual(
          OWNER_PROPERTY_CARD_TYPE[OwnerPropertyCardType.ONBOARDING]
        );
      });
    });

    describe("floorDoor", () => {
      it("Should return floor and door separated by a space if they exist", () => {
        // given
        const card = component();
        // when
        // then
        expect(card.vm.floorDoor).toEqual("2-3");
      });

      it("Should return an empty string if floor and door are null", () => {
        // given
        defaultProps.property.floor = "";
        defaultProps.property.door = "";
        const card = component({}, defaultProps);
        // when
        // then
        expect(card.vm.floorDoor).toEqual("");
      });
    });

    describe("locationName", () => {
      it("should show district and city name if they are not null", () => {
        // given
        const card = component();
        // when
        const result = card.vm.locationName;
        const expectedResult = "Sants-Montjuïc, Barcelona";
        // then
        expect(result).toEqual(expectedResult);
      });

      it("should show neighborhood and city name if they are not null and district is null", () => {
        // given
        defaultProps.property.location = {
          id: 1801,
          name: "Hostafrancs",
          state: { id: 1650, name: "Barcelona" },
          city: { id: 1737, name: "Barcelona" },
          district: { id: 1799, name: "" },
          neighborhood: { id: 1801, name: "Hostafrancs" },
        };
        const card = component({}, defaultProps);
        // when
        const result = card.vm.locationName;
        const expectedResult = "Hostafrancs, Barcelona";
        // then
        expect(result).toEqual(expectedResult);
      });

      it("should show city and state name if they are not null", () => {
        // given
        defaultProps.property.location = {
          id: 1801,
          name: "Hostafrancs",
          state: { id: 1650, name: "Barcelona" },
          city: { id: 1737, name: "Barcelona" },
          district: { id: 1799, name: "" },
          neighborhood: { id: 1801, name: "" },
        };
        const card = component({}, defaultProps);
        // when
        const result = card.vm.locationName;
        const expectedResult = "Barcelona, Barcelona";
        // then
        expect(result).toEqual(expectedResult);
      });

      it("should return null", () => {
        // given
        defaultProps.property.location = {
          id: 1801,
          name: "Hostafrancs",
          state: { id: 1650, name: "" },
          city: { id: 1737, name: "Barcelona" },
          district: { id: 1799, name: "" },
          neighborhood: { id: 1801, name: "" },
        };
        const card = component({}, defaultProps);
        // when
        const result = card.vm.locationName;
        const expectedResult = null;
        // then
        expect(result).toEqual(expectedResult);
      });
    });

    describe("mainPhoto", () => {
      it("Should return the main photo if card type is not onboarding", () => {
        cardTypeLead.propertyStatus = PropertyStatus.PUBLISHED;
        cardTypeLead.isVisible = true;
        cardTypeLead.activeRentalLeasings = [];
        const card = component({}, { property: cardTypeLead });
        // when
        // then
        expect(card.vm.mainPhoto).toEqual(defaultProps.property.mainPhoto);
      });
    });

    describe("statusSubtitle", () => {
      it("Should return null if there isn't statusData", () => {
        // given
        const card = component({}, { property: noStatusDataLead });
        // when
        // then
        expect(card.vm.statusSubtitle).toBe("common.fromDate");
      });

      it("If needsDateInfo should return correct date subtitle", () => {
        // given
        cardTypeLead.propertyStatus = PropertyStatus.LOST;
        cardTypeLead.activeRentalLeasings = [];
        const card = component({}, { property: cardTypeLead });
        spyOn(DateUtils, "formatDate").and.returnValue({
          day: "15",
          month: "june",
          year: "2022",
        });
        // when
        // then
        expect(card.vm.statusSubtitle).toBe("15 common.juneShort 2022");
      });

      it("If not needsDateInfo should return subtitle as it is", () => {
        // given
        const card = component();
        // when
        // then
        expect(card.vm.statusSubtitle).toEqual("common.completePendingTasks");
      });
    });

    describe("subtitleStyle", () => {
      it("Should return null if there isn't statusData", () => {
        // given
        const card = component({}, { property: noStatusDataLead });
        // when
        // then
        expect(card.vm.subtitleStyle).toBe(
          PROPERTY_CARD_SUBTITLE_TYPE[PropertyCardSubtitleType.DEFAULT]
        );
      });

      it("Should return correct subtitleStyle", () => {
        // given
        const card = component();
        // when
        // then
        expect(card.vm.subtitleStyle).toEqual(
          PROPERTY_CARD_SUBTITLE_TYPE[PropertyCardSubtitleType.ALERT]
        );
      });

      it("Should return default subtitleStyle", () => {
        // given
        cardTypeLead.propertyStatus = PropertyStatus.LOST;
        cardTypeLead.activeRentalLeasings = [];
        const card = component({}, { property: cardTypeLead });
        // when
        // then
        expect(card.vm.subtitleStyle).toEqual(
          PROPERTY_CARD_SUBTITLE_TYPE[PropertyCardSubtitleType.DEFAULT]
        );
      });
    });

    describe("badgeStyle", () => {
      it("Should return null if there isn't statusData", () => {
        // given
        const card = component({}, { property: noStatusDataLead });
        // when
        // then
        expect(card.vm.badgeStyle).toBe(
          PROPERTY_CARD_BADGE_TYPE[PropertyCardBadgeType.DEFAULT]
        );
      });

      it("Should return correct badgeStyle", () => {
        // given
        cardTypeLead.propertyStatus = PropertyStatus.RENTAL_MANAGEMENT;
        cardTypeLead.activeRentalLeasings = [
          {
            leasingId: 1,
            status: RentalLeasingStatus.ONGOING,
            lastStatusChangedAt: "",
          },
        ];
        const card = component({}, { property: cardTypeLead });
        // when
        // then
        expect(card.vm.badgeStyle).toEqual(
          PROPERTY_CARD_BADGE_TYPE[PropertyCardBadgeType.SUCCESS]
        );
      });

      it("Should return default badgeStyle", () => {
        // given
        const card = component();
        // when
        // then
        expect(card.vm.badgeStyle).toEqual(
          PROPERTY_CARD_BADGE_TYPE[PropertyCardBadgeType.DEFAULT]
        );
      });
    });
  });
});
